import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Theme/Styles/styles.module.css';
import localization from '../Language/localization';
import themes from '../Theme/Themes';
import { ActionButton, Link, ProgressCircle, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import ConversationSummaryFeedback from './ConversationSummaryFeedback';
import SentimentPositive from '../Theme/Icons/SentimentPositive';
import SentimentNegative from '../Theme/Icons/SentimentNegative';
import SentimentNeutral from '../Theme/Icons/SentimentNeutral';
import useFetchAgentPathSentimentsHook from '../hooks/useFetchAgentPathSentimentsHook';
import useGetClosedContactPathSentimentHook from '../hooks/useGetClosedContactPathSentimentHook';
import { RootState } from '../Store/Reducers/rootReducer';
import { setClosedContactSentiments } from '../Store/actions/customerInfoActions';
// import TrendLine from './TrendLine';

type Props = {
  agentPath: Array<{ DisplayName: string; ContactId: string; ParticipantId: string }> | string;
  feedbackGotten: any;
  callBackEvent: (eventType: string, eventData: any) => void;
  conversationSummary: {
    [key: string]: any;
  };
  conversationSentiments: {
    [contactId: string]: string;
  };
  conversationSentimentsLoading: {
    [contactId: string]: boolean;
  };
  currentConvId: string;
  initialContactId: string;
  sentimentEnabled: boolean;
  trendLineData: any;
  url: string;
};

const getSentimentIcon = (sentiment: string, darkMode: boolean) => {
  switch (sentiment) {
    case 'POSITIVE':
      return <SentimentPositive fill="#008f5d" style={{ marginLeft: '5px' }} />;
    case 'NEGATIVE':
      return <SentimentNegative fill="#d3150f" style={{ marginLeft: '5px' }} />;
    case 'NEUTRAL':
      return <SentimentNeutral fill={darkMode ? "#b3b3b3" : "#464646"} style={{ marginLeft: '5px' }} />;
    default:
      return undefined
  }
}

const getSentimentText = (sentiment: string) => {
  switch (sentiment) {
    case 'POSITIVE':
      return 'Positive'
    case 'NEUTRAL':
      return 'Neutral'
    case 'NEGATIVE':
      return 'Negative'
    default:
      return ''
  }
};

function ConversationSummary({
  conversationSummary,
  conversationSentiments,
  conversationSentimentsLoading,
  agentPath,
  callBackEvent,
  feedbackGotten,
  currentConvId,
  initialContactId,
  sentimentEnabled,
  trendLineData,
  url,
}: Props) {
  if (trendLineData) console.log('trendlineData: ', trendLineData)
  const language = useSelector((state: any) => state.customerInfo.language);
  const darkMode = useSelector((state: any) => state.customerInfo.darkMode);
  const { darkModeFieldValues, darkModea } = themes(darkMode);
  const { isConversationActive, closedContactSentiments } = useSelector((state: RootState) => state.customerInfo);
  const { loading: fetchingSentiments, fetchOrGenerateSentiments } = useFetchAgentPathSentimentsHook(url);
  const { loading: fetchingClosedSentiments, getSentiments } = useGetClosedContactPathSentimentHook(url);
  const dispatch = useDispatch();

  useEffect(() => {
    const isAnySentimentMissing = Array.isArray(agentPath) && !agentPath.every(path => conversationSentiments[path.ContactId] || conversationSentiments[`${path.ContactId}-BOT`])
    const isBotLegEmpty = Array.isArray(agentPath) && agentPath.some(path => conversationSentiments[`${path.ContactId}-BOT`] === '-')
    const isBotLegEmptyOrSentimentMissing = isAnySentimentMissing || isBotLegEmpty
    if (isBotLegEmptyOrSentimentMissing && !fetchingSentiments && isConversationActive) {
      fetchOrGenerateSentiments(Array.isArray(agentPath) ? agentPath.map(agent => agent.DisplayName === 'BOT' ? `${agent.ContactId}-BOT` : agent.ContactId) : [], initialContactId)
        .then(sentiments => {
          callBackEvent('SET_SENTIMENTS', sentiments)
        })
        .catch(err => console.log('Error in the ConversationSummary CIP fetching sentiments:  ', err?.message ?? 'Internal Error'));
    } else if (!fetchingClosedSentiments && !isConversationActive && initialContactId) {
      getSentiments(initialContactId)
        .then((res) => dispatch(setClosedContactSentiments(res?.listOfAllContactIdsSentiment ?? [])))
        .catch(err => console.log('Error in the ConversationSummary CIP fetching sentiments for a closed contact: ', err?.message ?? 'Internal Error'))
    }
  }, [agentPath, isConversationActive]);

  return (
    <div>
      <div className={styles['accordion-content-fields']}>
        <span className={styles['accordion-content-fields-keys-cip']}>{localization[language]['Conversation with']}</span>
        <div className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues}>
          {typeof agentPath === 'string' && agentPath}
          {sentimentEnabled &&
            Array.isArray(agentPath) &&
            isConversationActive &&
            agentPath?.map((contactLeg, idx) => {
              const sentiment = conversationSentiments?.[contactLeg.DisplayName === 'BOT' ? `${contactLeg.ContactId}-BOT` : contactLeg.ContactId]
              
              return (
                <div key={idx} style={{ display: 'flex', marginBottom: '5px' }}>{contactLeg.DisplayName}
                  {conversationSentimentsLoading?.[currentConvId] || fetchingSentiments ? (
                    <ProgressCircle marginStart='5px' size='S' isIndeterminate />
                  ) : (
                    <TooltipTrigger isDisabled={!sentiment || sentiment === '-'} placement='right'>
                      <ActionButton isDisabled={!sentiment || sentiment === '-'} height={18} isQuiet>
                        {getSentimentIcon(sentiment, darkMode) ?? <span>-</span>}
                      </ActionButton>
                      <Tooltip>{getSentimentText(sentiment ?? '')}</Tooltip>
                    </TooltipTrigger>
                  )}
                </div>
              )
            })}
            {sentimentEnabled &&
              Array.isArray(agentPath) &&
              !isConversationActive &&
              agentPath?.map((contactLeg, idx) => {
                const sentiment = Object.values(closedContactSentiments[idx] ?? {})[0]?.sentiment
                return (
                  <div key={idx} style={{ display: 'flex', marginBottom: '5px' }}>{contactLeg.DisplayName}
                    {conversationSentimentsLoading?.[currentConvId] || fetchingClosedSentiments ? (
                      <ProgressCircle marginStart='5px' size='S' isIndeterminate />
                    ) : (
                      <TooltipTrigger isDisabled={!sentiment || sentiment === '-'} placement='right'>
                        <ActionButton isDisabled={!sentiment || sentiment === '-'} height={18} isQuiet>
                          {getSentimentIcon(sentiment ?? '', darkMode) ?? <span>-</span>}
                        </ActionButton>
                        <Tooltip>{getSentimentText(sentiment ?? '')}</Tooltip>
                      </TooltipTrigger>
                    )}
                  </div>
                )
              })}
          {!sentimentEnabled && Array.isArray(agentPath) && agentPath.map(agent => agent.DisplayName).join(' -> ')}
        </div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Main customer issue']}</div>
        <div className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues}>{conversationSummary?.main_issue ?? '- No data -'}</div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Conversation Points']}</div>
        <div className={styles['accordion-conversation-summary-conversation-points']}>
          <ul>
            {conversationSummary?.conversation_points && conversationSummary?.conversation_points.length > 0
              ? conversationSummary?.conversation_points
                  ?.filter(point => point.trim().length)
                  .map((point, index) => (
                    <li className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues} key={index}>
                      {point ?? '- No data -'}
                    </li>
                  ))
              : '- No data -'}
          </ul>
        </div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary-links']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Links Provided']}</div>
        {conversationSummary?.links && conversationSummary?.links?.length > 0
          ? conversationSummary?.links?.map((link: string, index: number) => {
              // all links should be non-relative, handle case the link doesn't start with http or //
              const redirectLink = link.slice(0, 4).toLowerCase() === 'http' || link.slice(0, 2) === '//' ? link : `//${link}`;
              return (
                <Link UNSAFE_className={styles['accordion-content-fields-values-isnota-conversation-summary-links']} key={index}>
                  <a href={redirectLink} target="_blank" className={styles['accordion-content-fields-values-isnota'] + darkModea} rel="noreferrer">
                    {link}
                  </a>
                </Link>
              );
            })
          : '- No data -'}
      </div>
      {/* {sentimentEnabled && <TrendLine trendLineData={trendLineData} />} */}
      {isConversationActive && <ConversationSummaryFeedback feedbackGotten={feedbackGotten} currentConvId={currentConvId} callBackEvent={callBackEvent} />}
    </div>
  );
}

export default ConversationSummary;
