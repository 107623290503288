import React from 'react';
import { useSelector } from 'react-redux';
import { Text, Flex, Heading, View } from '@adobe/react-spectrum';

import styles from '../Theme/Styles/styles.module.css';
import localization from '../Language/localization';

type productCardType = {
    productName: string
    price: string
    commitment: string
    term: string
    currency: string
    language: string
}

const ProductCard = ({ productName, price, commitment, term, currency, language }: productCardType) => (
    <View borderRadius="medium" padding="size-150" backgroundColor="gray-200">
        <Flex direction="column">
            <Heading level={4}>{productName}</Heading>
            <Text UNSAFE_className={styles['cart-details-price']}>{`${currency} ${price}`}</Text>
            {commitment && (
                <Text>
                    {`${localization[language]['Commitment']}: ${commitment}`}
                </Text>
            )}
            {term && (
                <Text>
                    {`${localization[language]['Term']}: ${term}`}
                </Text>
            )}
        </Flex>
    </View>
);

export const CartDetails = () => {

    const { language, cartDetails }  = useSelector((state: any) => state.customerInfo)

    const { cartProductsList = [], cartOfferDetails = [], cartOfferIdList = [], currency = '' } = cartDetails

    return (
        cartProductsList.length > 0 && (
            <div className={styles['accordion-content-fields-conversation-summary']}>
                <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>
                    {localization[language]['Cart Details']}
                </div>
                <Flex direction="column" gap="size-150">
                    {cartProductsList.map((product, index) => {
                        const { productName = '', price = '' } = product;
    
                        // Get the offer ID for the current product
                        const offerId = cartOfferIdList[index];
                        
                        // Find the corresponding offer details using the offer ID
                        const offerDetails = cartOfferDetails.find(offer => offer.offer_id === offerId);
                        
                        // Extract term and commitment from the offer details, if available
                        const term = offerDetails?.term || '';
                        const commitment = offerDetails?.commitment || '';

                        return (
                            <ProductCard
                                key={index}
                                productName={productName}
                                price={price}
                                commitment={commitment}
                                term={term}
                                currency={currency}
                                language={language}
                            />
                        );
                    })}
                </Flex>
            </div>
        )
    );
};
