import tableStyles from '../../Theme/Styles/table.module.css';
import styles from '../../Theme/Styles/styles.module.css';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import localization from '../../Language/localization';
import { ProgressCircle } from '@adobe/react-spectrum';
import themes from '../../Theme/Themes';
import { updatesearchCustomerResPI, showfraudAlertStatus, updatePresistChatInfo } from '../../Store/actions/customerInfoActions';
import { initDunamis, customerAccountSelectionEvent, customerSearchResultRenderEvent } from '../../Analytics/danalytics';
import Alert from '../../Theme/Icons/Alert';

interface Props {
  contactOrigin: string;
  urls: {
    HENDRIX_DOMAIN: string;
    CM_DOMAIN: string;
    CIP_DOMAIN: string;
    CCP_API_END_POINT: string;
  };
  callBackEvent: (type: string, data: any) => void;
}

//List of column name and className
const thead = [
  ['page.customer.table.column1', 'table-first-name-column'],
  ['page.customer.table.column2'],
  ['page.customer.table.column3', 'table-company-column'],
  ['page.customer.table.column4', 'table-email-column'],
  ['page.customer.table.column5', 'table-phone-column'],
  ['page.customer.table.column6', 'table-country-column'],
  ['page.customer.table.column7', 'table-status-column'],
];

const Table = (prop: Props) => {
  const { urls, contactOrigin, callBackEvent } = prop;
  const dispatch = useDispatch();
  const { SCIP_data, currentContactId, darkMode, searchCustomer, isSearchCustomerLoading, language, presistChatInfo } = useSelector((state: any) => state.customerInfo);
  const vaoWorkflowCategory = contactOrigin.includes('chat') ? 'Sales Chat' : 'Sales Telephony'; // For VAO Analytics

  console.log('scip SEARCH_CUSTOMER', searchCustomer);
  //Theme
  const { darkModeaAdobeBold, darkModeaAdobeRegular, darkModea } = themes(darkMode);

  useEffect(() => {
    const customerSearchResultCount = searchCustomer?.length || 0;
    // Analytics for value added offers - VAO
    initDunamis();
    customerSearchResultRenderEvent('Sales CIP', vaoWorkflowCategory, customerSearchResultCount);
  }, []);

  //Fraud Alert
  const updatePersonalInfo = (data: any): any => {
    dispatch(updatesearchCustomerResPI(urls, SCIP_data, data, currentContactId, callBackEvent));
    callBackEvent('UPDATE_CUSTOMER_INFO', data);
    // Analytics for value added offers - VAO
    customerAccountSelectionEvent('Sales CIP', vaoWorkflowCategory, data?.rengaId);
    data.contactId = currentContactId;
    callBackEvent('UPDATE_CUSTOMER_INFO', data);

    if (presistChatInfo[currentContactId].searchCustomer) {
      presistChatInfo[currentContactId].searchCustomer = '';
      dispatch(updatePresistChatInfo(presistChatInfo));
    }
    const isFraudAlert = data.temporaryStatus && (data.temporaryStatus.toLowerCase() === 'accountfraudlock' || data.temporaryStatus.toLowerCase() === 'legallock');
    if (isFraudAlert) dispatch(showfraudAlertStatus({ show: isFraudAlert, message: data.temporaryStatus }));
    else dispatch(showfraudAlertStatus({ show: false, message: '' }));
  };

  return isSearchCustomerLoading ? (
    <div className={styles['cip-loader']}>
      <ProgressCircle UNSAFE_className={styles['cip-module-fetch-loader']} aria-label="Loading…" isIndeterminate />
      <p className={`${styles['cip-loader-text']} ${styles['adobe-clean-regular']}` + darkModeaAdobeRegular}>{localization[language]['loader-fetching-customer-profile']}</p>
    </div>
  ) : searchCustomer.length > 0 && searchCustomer[0] !== 'NO_DATA' && searchCustomer[0] !== 'notFound' ? (
    <div>
      <p className={styles['adobe-clean-bold-search'] + darkModeaAdobeBold}>{localization[language]['page.customer.title']}</p>
      <table className={darkMode ? `${tableStyles['custom-table']} ${tableStyles['table-dark']}` : `${tableStyles['custom-table']}`}>
        <thead className={tableStyles['thead']}>
          <tr>
            {thead.map(head => (
              <th scope="col" className={head[1] ? tableStyles[head[1]] : ''} key={head[0]}>
                {localization[language][head[0]]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={tableStyles['tbody']}>
          {searchCustomer.map(
            (data: any, index: number) =>
              data.type &&
              data.type.toLowerCase() !== 'type2e' && (
                <tr key={index} onClick={() => updatePersonalInfo(data)}>
                  {/* Define class for specific column if needed */}
                  <td title={data.firstName}>{data.firstName}</td>
                  <td title={data.lastName}>{data.lastName}</td>
                  <td title={data.orgName} className={tableStyles['cip-org-name']}>
                    {data.orgName}
                  </td>
                  <td title={data.email} className={tableStyles['cip-email-address']}>
                    {data.email}
                  </td>
                  <td title={data.phone} className={tableStyles['cip-phone-number']}>
                    {data.phone}
                  </td>
                  <td title={data.countryCode}>{data.countryCode}</td>
                  <td title={data.status === '0' ? 'Active' : 'Inactive'}>{data.status === '0' ? 'Active' : 'Inactive'}</td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  ) : searchCustomer[0] !== 'NO_DATA' && searchCustomer[0] === 'notFound' ? (
    <div className={styles['scip-customer-not-found']}>
      <div className={styles['scip-customer-not-found-alret']}>
        <Alert />
        <p className={styles['adobe-clean-bold'] + darkModeaAdobeBold}>{localization[language]['page.search.message.notfound']}</p>
      </div>
      <a target="_blank" href={`${urls.HENDRIX_DOMAIN}/app/search`} className={styles['scip-add-customer'] + darkModea} rel="noreferrer">
        {localization[language]['page.customer.button.add']}
      </a>
    </div>
  ) : null;
};

export default Table;
