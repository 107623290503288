import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loader, getOrderQuote } from '../Store/actions/customerInfoActions';

const NO_ORDER = ['No Order found'];
const NO_QUOTE = ['No Quote found'];

const useOrderQuote = (isCIP: boolean, CIP_DOMAIN: string, reload: number) => {
  const dispatch = useDispatch();
  const { userPersisted, isConversationActive }: any = useSelector((state: any) => state.customerInfo);
  const { linkedCustomer: { email = '' } = {}, conversationId = '' } = userPersisted;
  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();
    try {
      if (!isCIP && isMounted && email.match(/@/g)) {
        console.log('SCIP order quotes before order quotes ', userPersisted);
        dispatch(loader(true));
        const orderAndQuotes = async () =>
          axios
            .get(`${CIP_DOMAIN}/api/v1/orders/commerce`, {
              params: { email: email },
              headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
              cancelToken: source.token,
            })
            .then((res: any): any => {
              const ordersArray = res.data?.json?.orderDetailsAfterSort.map((item: any) => item.id);
              const quotesArray = res.data?.json?.quoteDetailsAfterSort.map((item: any) => item.id);
              const orderQuoteData = {
                Order: NO_ORDER,
                Quote: NO_QUOTE,
              };
              if (ordersArray?.length > 0) orderQuoteData.Order = ordersArray;
              if (quotesArray?.length > 0) orderQuoteData.Quote = quotesArray;

              dispatch(getOrderQuote(isCIP, orderQuoteData));
              console.log('SCIP order quotes after ', email, orderQuoteData, isCIP);
            })
            .catch(e => {
              if (isMounted) console.log('SCIP order quotes errors: .catch', e);
            })
            .finally(() => {
              isMounted && dispatch(loader(false));
              setTimeout(() => {
                // stop CIP analytics
                (window as any).enable_CIP = false;
              }, 100);
            });

        orderAndQuotes();
      } else if (!isCIP && isMounted && (!userPersisted?.linkedCustomer?.email || userPersisted?.linkedCustomer?.email === '')) {
        const orderQuoteData = {
          Order: NO_ORDER,
          Quote: NO_QUOTE,
        };
        dispatch(getOrderQuote(isCIP, orderQuoteData));
        dispatch(loader(false));
      }
    } catch (error) {
      if (isMounted) {
        dispatch(loader(false));
        console.log('SCIP order quotes errors:', error);
      }
    }
    return () => {
      isMounted = false;
      source.cancel();
    };
  }, [email, conversationId, isConversationActive, reload]);
};

export default useOrderQuote;
