import {
  DART_THEME,
  LANGUAGE,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_LOADING,
  UPDATE_CIP_DATA,
  UPDATE_SCIP_DATA,
  UPDATE_IFRAME_HEIGHT,
  SHOW_SEARCH_CUSTOMER_TABLE,
  CURRENT_CONTACT_ID,
  FRAUD_ALERT_STATUS,
  LOADER,
  USER_PERSISTED,
  CUSTOMER_OFFER,
  ORDER_QUOTE,
  SET_CIS_LOADER,
  CONVERSATION_STATUS,
  JCAUTHDATA,
  PRESISTCHATINFO,
  CIS_ALLOWED_AGENT,
  PHONE_VALIDATION,
  CART_DETAILS
} from '../constants/customerInfoConstants';
import localization from '../../Language/localization';

const CIP_data: any = [];

const SCIP_data: any = [];
const darkMode = true;
const language = 'en';
const searchCustomer: any[] = ['NO_DATA'];
const isSearchCustomerLoading = false;
const iFrameHeightCIS = '';
const isShowSearchCustomerTable = false;
const setFraudAlertStatus: any = {
  show: false,
  message: '',
};
const currentContactId = '';
const loading = false;
const userPersisted: any = [];
const customerOfferList: any = [{ features: ['---'], meta: [], entitlement: ['---'] }];
const orderQuote: any = {
  Order: [localization[language]['page.orderDetails.order.notFound']],
  Quote: [localization[language]['page.orderDetails.quote.notFound']],
};
const isCISLoading = false;
const isCISallowed = false;
const isConversationActive = false;
const jcAuthData: any = {};
const presistChatInfo = {};
const cartDetails = {};

export type CustomerInfoState = {
  CIP_data: any;
  SCIP_data: any;
  searchCustomer: any;
  isSearchCustomerLoading: any;
  darkMode: any;
  language: any;
  iFrameHeightCIS: any;
  isShowSearchCustomerTable: any;
  currentContactId: any;
  setFraudAlertStatus: any;
  loading: any;
  userPersisted: any;
  customerOfferList: any;
  orderQuote: any;
  isCISLoading: any;
  isConversationActive: boolean;
  jcAuthData: any;
  presistChatInfo: any;
  phoneValid: any;
  isCISallowed: any;
  cartDetails: any;
  closedContactSentiments: {
    [contactId: string]: {
      sentiment: string
    }
  }[]
}

const customerInfoReducer = (
  state: CustomerInfoState = {
    CIP_data,
    SCIP_data,
    searchCustomer,
    isSearchCustomerLoading,
    darkMode,
    language,
    iFrameHeightCIS,
    isShowSearchCustomerTable,
    currentContactId,
    setFraudAlertStatus,
    loading,
    userPersisted,
    customerOfferList,
    orderQuote,
    isCISLoading,
    isConversationActive,
    jcAuthData,
    presistChatInfo,
    phoneValid: false,
    isCISallowed,
    cartDetails,
    closedContactSentiments: []
  },
  action: any,
): CustomerInfoState => {
  switch (action.type) {
    case LOADER:
      return { ...state, loading: action.payload };
    case SET_CIS_LOADER:
      return { ...state, isCISLoading: action.payload };
    case UPDATE_CIP_DATA:
      return { ...state, CIP_data: action.payload };
    case UPDATE_SCIP_DATA:
      return { ...state, SCIP_data: action.payload };
    case SEARCH_CUSTOMER:
      return { ...state, searchCustomer: action.payload };
    case SEARCH_CUSTOMER_LOADING:
      return { ...state, isSearchCustomerLoading: action.payload };
    case CURRENT_CONTACT_ID:
      return { ...state, currentContactId: action.payload };
    case DART_THEME:
      return { ...state, darkMode: action.payload };
    case LANGUAGE:
      return { ...state, language: action.payload };
    case UPDATE_IFRAME_HEIGHT:
      return { ...state, iFrameHeightCIS: action.payload };
    case FRAUD_ALERT_STATUS:
      return { ...state, setFraudAlertStatus: action.payload };
    case SHOW_SEARCH_CUSTOMER_TABLE:
      return { ...state, isShowSearchCustomerTable: action.payload };
    case USER_PERSISTED:
      return { ...state, userPersisted: action.payload };
    case CUSTOMER_OFFER:
      return { ...state, customerOfferList: action.payload };
    case ORDER_QUOTE:
      return { ...state, orderQuote: action.payload };
    case CONVERSATION_STATUS:
      return { ...state, isConversationActive: action.payload };
    case JCAUTHDATA:
      return { ...state, jcAuthData: action.payload };
    case PRESISTCHATINFO:
      return { ...state, presistChatInfo: action.payload };
    case CIS_ALLOWED_AGENT:
      return { ...state, isCISallowed: action.payload };
    case PHONE_VALIDATION: 
      return {...state, phoneValid: action.payload}
    case CART_DETAILS: 
      return {...state, cartDetails: action.payload}
    case 'SET_CLOSED_CONTACT_SENTIMENTS':
      return {...state, closedContactSentiments: action.payload};
    default:
      return state;
  }
};

export default customerInfoReducer;
