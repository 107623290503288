declare const window: any;

/**
 * 
 * @param conversationId 
 * @param value 
 * 
 * This function shouldn't update the customerSearchClick value once it's updated to YES already.
 * Since Once the customer search happens, then search happens for that conversationId, we should not update the value again. 
 * Case Management/Sales CIP- id search
 */
export function updateCustomerSearchClick(value: string) {
    const conversationId = window.getCurrentConvID();
    let updatedItem = {};

    if(typeof window.updateCTRItem !== 'function') return;

    if (typeof window.getCTRItem === 'function') {
        const existingItem = window.getCTRItem(conversationId);

        if (existingItem?.customerSearchClick?.includes('Yes')) return;

        updatedItem = Object.assign({}, existingItem, { customerSearchClick: `${conversationId}: ${value}` });
    } else {
        updatedItem = { customerSearchClick: `${conversationId}: ${value}` };
    }

    return window.updateCTRItem(conversationId, updatedItem);
}