import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../Theme/Styles/styles.module.css';
import { TextField, Button, Tooltip, TooltipTrigger, ActionButton } from '@adobe/react-spectrum';

import Table from './SearchCustomerTable';
import themes from '../../Theme/Themes';

import localization from '../../Language/localization';
import { getcustomerInfo, showSearchCustomerTable, updatePresistChatInfo } from '../../Store/actions/customerInfoActions';
import { updateCustomerSearchClick } from '../../Analytics/ctr-analytics';
import { initDunamis, customerSearchEvent, customerAccountSelectionEvent, customerSearchResultRenderEvent } from '../../Analytics/danalytics';
import Alert from '../../Theme/Icons/Alert';

interface Props {
  contactOrigin: string;
  urls: {
    HENDRIX_DOMAIN: string;
    CM_DOMAIN: string;
    CIP_DOMAIN: string;
    CCP_API_END_POINT: string;
  };
  callBackEvent: (type: string, data: any) => void;
}

const SCIPCustomerInfo = (prop: Props) => {
  const { urls, callBackEvent, contactOrigin } = prop;
  const dispatch = useDispatch();
  const { currentContactId, isShowSearchCustomerTable, isConversationActive, language, setFraudAlertStatus, jcAuthData, userPersisted, presistChatInfo } = useSelector(
    (state: any) => state.customerInfo,
  );
  const { darkModea, darkModeaAdobeBold, darkModeaAdobeRegular, darkModeaAdobeRegularOr } = themes(useSelector((state: any) => state.customerInfo.darkMode));
  const { linkedCustomer } = useSelector((state: any) => state.customerInfo.userPersisted);
  const isAttributeAvailable = (attribute: string) => (attribute ? attribute : '');
  const [email, setEmail] = useState(
    presistChatInfo[currentContactId] && (presistChatInfo[currentContactId].email || presistChatInfo[currentContactId].email === '')
      ? presistChatInfo[currentContactId].email
      : isAttributeAvailable(linkedCustomer?.email),
  );
  const [phone, setPhone] = useState(presistChatInfo[currentContactId] && presistChatInfo[currentContactId].phone ? presistChatInfo[currentContactId].phone : '');
  const [isPrechatNotFound, setIsPrechatNotFound] = useState(false);
  useMemo(() => {
    setPhone(presistChatInfo[currentContactId] && presistChatInfo[currentContactId].phone ? presistChatInfo[currentContactId].phone : '');
    // NOSONAR
  }, [presistChatInfo[currentContactId] && presistChatInfo[currentContactId]]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      console.log('Presist ChatInfo on load', presistChatInfo, presistChatInfo[currentContactId]);
      if (!(presistChatInfo[currentContactId] && (presistChatInfo[currentContactId].email || presistChatInfo[currentContactId].email === '')))
        setEmail(linkedCustomer?.email ? linkedCustomer.email : '');
      else if (presistChatInfo[currentContactId] && (presistChatInfo[currentContactId].email || presistChatInfo[currentContactId].email === ''))
        setEmail(presistChatInfo[currentContactId].email);
      else setEmail('');
    }
    return () => {
      isMounted = false;
    };
  }, [linkedCustomer]);

  const isEmail = useMemo(
    // NOSONAR
    () => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email),
    [email],
  );

  const phoneSubstring = ['!', '@', '$', '*', '{', '}'];
  const isAlphabetregExp = /[a-zA-Z]/g;
  const isPhone = useMemo(
    () => phone.replace(/\D+/g, '').length > 4 && phone.replace(/\D+/g, '').length < 16 && !phoneSubstring.some(v => phone.includes(v)) && !isAlphabetregExp.test(phone),
    [phone],
  );
  const validatePhone = (value: string) => {
    const currentValue = value.replace(/[^.\-()+0-9]\s/g, '');
    setPhone(currentValue);
    presistChatInfo[currentContactId] = { ...presistChatInfo[currentContactId], phone: currentValue };
    dispatch(updatePresistChatInfo(presistChatInfo));
  };
  const validateEmail = (value: string) => {
    const currentValue = value.trim();
    setEmail(currentValue);
    presistChatInfo[currentContactId] = { ...presistChatInfo[currentContactId], email: currentValue };
    dispatch(updatePresistChatInfo(presistChatInfo));
  };
  const { CIP_DOMAIN } = urls;
  const searchCustomer = (e: any): any => {
    // Analytics for Value added offers - VAO
    const vaoWorkflowCategory = contactOrigin.includes('chat') ? 'Sales Chat' : 'Sales Telephony'; // For VAO Analytics

    if (e.keyCode === 13 || e.type === 'press') {
      setIsPrechatNotFound(false);
      dispatch(showSearchCustomerTable(true));
      // Analytics for Value added offers - VAO
      customerSearchEvent('Sales SCIP', vaoWorkflowCategory);
      updateCustomerSearchClick('Yes');

      if (isPhone && isEmail) return dispatch(getcustomerInfo(CIP_DOMAIN, currentContactId, email, phone.replace(/\D+/g, ''), presistChatInfo));
      else if (isEmail) return dispatch(getcustomerInfo(CIP_DOMAIN, currentContactId, email, '', presistChatInfo));
      else if (isPhone) return dispatch(getcustomerInfo(CIP_DOMAIN, currentContactId, '', phone.replace(/\D+/g, ''), presistChatInfo));
    }
  };

  useEffect(() => {
    const isPrechat = jcAuthData?.customerType && jcAuthData.email && jcAuthData.customerType.toLowerCase() === 'unauthenticated';
    const rengaId = userPersisted?.linkedCustomer?.rengaId;

    console.log('SCIP Pre-chat CX not found', isPrechat, userPersisted);
    // analytics for Value added offers - VAO
    initDunamis();
    updateCustomerSearchClick('No');

    if (!/^\d+$/.test(jcAuthData.customerId)) setIsPrechatNotFound(isPrechat && rengaId && !(rengaId.match(/adobeid/gi) || rengaId.match(/@/gi)));
  }, []);

  const isValid = (value: boolean) => (value ? 'valid' : 'invalid');
  const frSearchBoxAignment = language === 'fr' ? 'scip-customer-search-or-no-but-fr' : 'scip-customer-search-or-no-but';

  return (
    <div className={styles['scip-accordion-content']}>
      <p className={styles['adobe-clean-bold'] + darkModeaAdobeBold}>{localization[language]['Customer Search']}</p>
      <p className={styles['adobe-clean-regular'] + darkModeaAdobeRegular}>{localization[language]['page.search.subtitle']}</p>
      <div className={styles['scip-customer-search-with-genuine']}>
        <div className={styles['scip-customer-search']}>
          <div className={styles[frSearchBoxAignment]}>
            <TextField
              label={localization[language]['page.search.email']}
              inputMode="email"
              type="email"
              placeholder={localization[language]['page.search.placeholder']}
              UNSAFE_className={styles['spectrum-text-field']}
              value={email}
              onChange={validateEmail}
              onKeyDown={searchCustomer}
              validationState={email ? isValid(isEmail) : undefined}
              isDisabled={!isConversationActive}
            />
          </div>
          <div className={styles[frSearchBoxAignment]}>
            <span className={styles['adobe-clean-regular-or'] + darkModeaAdobeRegularOr}>{localization[language]['cip-or']}</span>
          </div>
          <div className={styles[frSearchBoxAignment]}>
            <TextField
              label={localization[language]['page.search.phone']}
              type="tel"
              inputMode="tel"
              placeholder={localization[language]['page.search.placeholder']}
              UNSAFE_className={styles['spectrum-text-field']}
              value={phone}
              onChange={validatePhone}
              onKeyDown={searchCustomer}
              validationState={phone ? isValid(isPhone) : undefined}
              isDisabled={!isConversationActive}
            />
          </div>
          <div className={styles[frSearchBoxAignment]}>
            <Button
              variant="primary"
              UNSAFE_style={{ marginTop: '25px', marginLeft: '10px' }}
              onPress={searchCustomer}
              isDisabled={!(isConversationActive && (isEmail || isPhone))}>
              {localization[language]['page.search.submit']}
            </Button>
          </div>
        </div>

        {setFraudAlertStatus.show && (
          <TooltipTrigger crossOffset={10} delay={100} placement="right">
            <ActionButton aria-label="Edit Name" UNSAFE_className={styles['scip-spectrum-action-button']}>
              <span className={styles['red-dot']}> </span>
              <p className={styles['adobe-clean-regular-non-genuine']}>{localization[language]['page.fraud.' + setFraudAlertStatus.message]}</p>
            </ActionButton>
            <Tooltip>{localization[language]['page.fraud.tooltip.' + setFraudAlertStatus.message]}</Tooltip>
          </TooltipTrigger>
        )}
      </div>
      {isShowSearchCustomerTable && <Table urls={urls} contactOrigin={contactOrigin} callBackEvent={callBackEvent} />}
      {isPrechatNotFound && (
        <div className={styles['scip-customer-not-found']}>
          <div className={styles['scip-customer-not-found-alret']}>
            <Alert />
            <p className={styles['adobe-clean-bold'] + darkModeaAdobeBold}>{localization[language]['page.search.message.notfound']}</p>
          </div>
          {isConversationActive && (
            <a target="_blank" href={`${urls.HENDRIX_DOMAIN}/app/search`} className={styles['scip-add-customer'] + darkModea} rel="noreferrer">
              {localization[language]['page.customer.button.add']}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default SCIPCustomerInfo;
