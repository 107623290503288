import React from 'react'

type Props = {
  fill?: string,
  style?: React.CSSProperties
}

const SentimentNegative = ({ fill = '#464646', style }: Props) => (
  <svg aria-labelledby='negative sentiment' style={style} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path style={{ fill }} d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1Zm2.7365,3.216a1.71921,1.71921,0,0,1,1.5,1.875,1.71921,1.71921,0,0,1-1.5,1.875,1.71921,1.71921,0,0,1-1.5-1.875A1.71921,1.71921,0,0,1,11.7365,4.216Zm-5.554.051a1.71855,1.71855,0,0,1,1.5,1.874,1.71956,1.71956,0,0,1-1.5,1.875,1.71956,1.71956,0,0,1-1.5-1.875A1.71819,1.71819,0,0,1,6.1825,4.267Zm7.256,8.054-.471.238a.5.5,0,0,1-.562-.076c-.1665-.15-.3635-.3295-.4145-.365a5.24348,5.24348,0,0,0-2.9705-.868,5.237,5.237,0,0,0-3,.8855c-.062.044-.2445.212-.4.3585a.5.5,0,0,1-.567.0805l-.464-.235a.5.5,0,0,1-.145-.782c.116-.1285.221-.2415.263-.279A6.504,6.504,0,0,1,9.0205,9.75a6.48448,6.48448,0,0,1,4.3645,1.575c.0235.0215.104.1095.2.216a.5.5,0,0,1-.1465.78Z" />
  </svg>
)

export default SentimentNegative
