import React, { useState, useEffect } from "react";
import styles from "../Theme/Styles/transferCallPopup.styles.css"
import {
    Button,
    Flex,
    Text,
    Picker,
    Item,
    Footer,
    ActionButton,
    StatusLight,
    ComboBox,
    TooltipTrigger,
    Tooltip,
    ToggleButton
} from "@adobe/react-spectrum"
import { getQueueStatus } from '../api/queueStatusAPI'
import axios from 'axios'
import localization from "../lang/localization";
import { ConversationAssigned, Close, ChevronLeft, Clock } from '../Theme/Icons/ToolbarIcons';
import { Alert } from "../Theme/Icons/DialpadIcons";

declare const window: any;
const transferNotesLimit = 1024;
const statusVariantMap = {
    'NA': 'neutral',
    'Available': 'positive'
}
const TAB_ID = {
    'TRANSFER_TO_ASSISTANCE': 1,
    'DIRECT_TRANSFER': 2
}
const TRANSFER_VOICE_CALL_TYPE = {
    WARM: 'warm',
    COLD: 'cold'
}

const CHANNEL = {
    'CHAT': 'CHAT',
    'VOICE': 'VOICE'
}

type iTransferDataProps = {
    tabId: number
    transferTabActive: string
    transferToAssistance: any
    messageAssistance: string
    transferToDirect: any
    messageDirect: string
    resultQueue: any,
    resultQueueList: any
    overrideSuggestion: string
    nonEnglishQueue: boolean
    isSecureIVR: boolean
    isVoicemailMessage: boolean
    isDialExtension: boolean
}


const initData: iTransferDataProps = {
    tabId: 1,
    transferTabActive: 'Queue', // like queue or manager
    transferToAssistance: {}, // queue name or manager name 
    messageAssistance: '', // private notes
    transferToDirect: {}, // queue name or manager name 
    messageDirect: '', // private notes
    resultQueue: {}, // tap-tool-queue obj
    resultQueueList: [], // tap tool list
    overrideSuggestion: '', // tap tool override suggestion ie reason,
    nonEnglishQueue: false,
    isSecureIVR: false,
    isVoicemailMessage: false,
    isDialExtension: false
}

let propQueueCheck = 'en' ;
let TapTool:any = {};
let TAP_TOOL_DEFAULT:any = {};
let tapToolAPIData:any = {};

const TransferCall = (props: any) => {
    const { lang, endpoint, headers, transferFun, transferQueueData, darkMode, currentConversation, rtq, close, closePopup, saveTransferSelections, isDialpadTransfer = '', callBackEvent, isTransfer,
        quickConnectConfiguration = { Queue: false, SecureIVR: false, Manager: false, VoicemailMessage: false, DialExtension: false },
        allActiveTransferData = {}, routingProfileQueues = []
    } = props;

    const [tabId, setTabId] = useState(TAB_ID.TRANSFER_TO_ASSISTANCE);
    const [transferData, setTransferData] = useState<iTransferDataProps>(initData);
    const [queueSearchText, setQueueSearchText] = useState('');
    const [directSearchText, setDirectSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [transferQueue, setTransferQueue] = React.useState<any>({})
    const [loadingState, setLoadingState] = useState<any>('loadingMore');
    const [overrideSuggestion, setOverrideSuggestion] = useState(false);
    const [transferState, setTransferState] = useState('');
    const [agentRoutingProfileQueues, setAgentRoutingProfileQueues] = useState<any>([]); 
    const {
        jcAuthData: { transferCount: { value: tranferCnt = 0 } = {}, currentQueue: { value: propQueue = '' } = {}, origin: { value: jcOrigin = '' } = {}, } = {},
        ocPlatformData: {
            chatInfo: { currentContactId = '' } = {},
            callInfo: { origin: callOrigin = '', status: CallStatus = '' } = {}
        } = {}
    } = currentConversation;
    const origin = callOrigin || jcOrigin;
    // const phoneOrigin = [PHONE_ORIGIN_TYPE.BOT_TO_PHONE, PHONE_ORIGIN_TYPE.INBOUND_PHONE, PHONE_ORIGIN_TYPE.OUTBOUND_PHONE];
    let source: any = null;

    useEffect(() => {
        tapToolAPIData = window?.tapToolAPIData || {};
        TapTool = tapToolAPIData?.["TapToolEn.json"] || { "options": [] };
        TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolEn.json"]?.["/"] || { "options": [] };
    }, [])

    useEffect(() => {
        if (tapToolAPIData) {
            if (propQueue.includes('-Ja')) {
                TapTool = tapToolAPIData?.["TapToolJa.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolJa.json"]?.["/"]
                propQueueCheck = 'ja';
            } else if (propQueue.includes('-Ko')) {
                TapTool = tapToolAPIData?.["TapToolKo.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolKo.json"]?.["/"]
                propQueueCheck = 'ko';
            } else if (propQueue.includes('-Fr')) {
                TapTool = tapToolAPIData?.["TapToolFr.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolFr.json"]?.["/"]
                propQueueCheck = 'fr';
            } else if (propQueue.includes('-It')) {
                TapTool = tapToolAPIData?.["TapToolIt.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolIt.json"]?.["/"]
                propQueueCheck = 'it';
            } else if (propQueue.includes('-Es')) {
                TapTool = tapToolAPIData?.["TapToolEs.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolEs.json"]?.["/"]
                propQueueCheck = 'es';
            } else if (propQueue.includes('-Pt')) {
                TapTool = tapToolAPIData?.["TapToolPt.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolPt.json"]?.["/"]
                propQueueCheck = 'pt-BR';
            } else if (propQueue.includes('-En')) {
                TapTool = tapToolAPIData?.["TapToolEn.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolEn.json"]?.["/"]
                propQueueCheck = 'en';

            } else if (propQueue.includes('-De')) {
                TapTool = tapToolAPIData?.["TapToolDe.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolDe.json"]?.["/"]
                propQueueCheck = 'de';
            } else {
                TapTool = tapToolAPIData?.["TapToolEn.json"];
                TAP_TOOL_DEFAULT = tapToolAPIData?.["TapToolEn.json"]?.["/"]
                propQueueCheck = 'en';
            }
        }
    }, [propQueue, currentContactId]);

    useEffect(() => {

        const updateTransferData = getTransferData();
        let nonEnglishQueue = false;

        const allowQueue = ['-Ja', '-Ko', '-Fr', '-It', '-Es', '-Pt', '-De'];
        if (!propQueue?.includes('-En')) {
            nonEnglishQueue = true;
        }

        if (allowQueue.some(substring => propQueue.includes(substring))) {
            nonEnglishQueue = false;
        }

        updateTransferData.nonEnglishQueue = nonEnglishQueue;

        if (rtq) {
            updateTransferData.transferToAssistance = transferQueueData.Queue?.find((x: any) => x.name === propQueue)
        }

        setTransferData(updateTransferData);

        if (updateTransferData?.nonEnglishQueue || quickConnectConfiguration.Queue || updateTransferData?.tabId === TAB_ID.DIRECT_TRANSFER) {
            setTabId(TAB_ID.DIRECT_TRANSFER);
            setFilteredItems(transferQueue?.Manager || []);
        } else {
            setTabId(TAB_ID.TRANSFER_TO_ASSISTANCE)
            setFilteredItems(transferQueue?.Queue || []);
        }

        const transferToAssistanceName = updateTransferData?.transferToAssistance?.name || '';
        const transferDirectName = updateTransferData?.transferToDirect?.name || '';
        setQueueSearchText((!rtq && transferToAssistanceName) ? transferToAssistanceName : '');
        setDirectSearchText((!rtq && transferDirectName) ? transferDirectName : '');

    }, [currentContactId, isDialpadTransfer])

    useEffect(() => {
        // Get QueueNameArray from transferQueueData
        let mounted = true;
        const channel = (origin && CallStatus === 'Active') ? CHANNEL.VOICE : CHANNEL.CHAT;
        if (transferQueueData && transferQueueData.Queue) {
            const queueArray = transferQueueData.Queue
                .filter((item: any) => item.name !== undefined)
                .map(function (queue: any) {
                    return queue.name
                })

            const agentRoutingProfileQueuesName = routingProfileQueues
            .map((item: any) => item.name)
            .filter(Boolean) || [];
            setAgentRoutingProfileQueues(agentRoutingProfileQueuesName);
            // payload for Get Queue Status API
            const payload = {
                "lookupType": "name",
                "queues": queueArray,
                "channel": channel
            }
            // Get Queue Status
            source = axios.CancelToken.source();
            const getQueueStatusResult = async (endpoint: string, payload: any, source: any, headers: any) => {
                try {
                    const res: any = await getQueueStatus(endpoint, payload, source, headers);
                    const { data: { data: { result: { queueStatuses = [] } = {} } = {} } = {} } = res;
                    if (mounted && queueStatuses.length) {
                        const parseQueueStatus = queueStatuses.filter((i: any) => {
                            return !i.disabled
                        }).map((i: any) => {
                            i.status = (i.open === true) ? 'Available' : 'NA'
                            return i
                        })
                        const newTransferQueueData: any = { ...transferQueueData };
                        newTransferQueueData.Queue = parseQueueStatus;
                        setTransferQueue(newTransferQueueData);
                    } else {
                        setTransferQueue(transferQueueData)
                    }
                }
                catch (err) {
                    if (mounted) {
                        setLoadingState('idle')
                        setTransferQueue(transferQueueData)
                    }
                }
            }
            getQueueStatusResult(endpoint, payload, source, headers)
        }

        const cleanUp = () => {
            mounted = false;
            if (source !== null) source.cancel()
        }
        return cleanUp
    }, [currentContactId])

    useEffect(() => {
        let activeOption = tabId === TAB_ID.TRANSFER_TO_ASSISTANCE ? 'Queue' : 'Manager';
        if (transferData?.nonEnglishQueue) {
            setTabId(TAB_ID.DIRECT_TRANSFER);
            activeOption = transferData?.transferTabActive || 'Queue'
            setFilteredItems(transferQueue?.[activeOption] || []);
        } else {
            setFilteredItems(transferQueue?.[activeOption] || []);
        }
        if (transferQueue?.Queue)
            setLoadingState('idle')
    }, [transferQueue])

    useEffect(() => {
        if (transferQueue?.Queue?.length > 0) {
            if (queueSearchText === '')
                setFilteredItems(transferQueue?.Queue || [])
            else
                setFilteredItems(transferQueue?.Queue?.filter((x: any) => x.name.toLowerCase().indexOf(queueSearchText.toLowerCase()) !== -1) || [])

        } else setFilteredItems([])
    }, [queueSearchText]);

    useEffect(() => {
        if (transferQueue?.Manager?.length > 0) {
            if (directSearchText === '')
                setFilteredItems(transferQueue?.Manager || [])
            else
                setFilteredItems(transferQueue?.Manager?.filter((x: any) => x.name.toLowerCase().indexOf(directSearchText.toLowerCase()) !== -1) || [])

        } else setFilteredItems([])
    }, [directSearchText])

    const getTransferData = () => {
        if (allActiveTransferData[currentContactId]) {
            const transferType = isDialpadTransfer ? 'voice' : 'chat';
            return allActiveTransferData?.[currentContactId]?.[transferType] || JSON.parse(JSON.stringify(initData));
        }
        return JSON.parse(JSON.stringify(initData));
    }

    const handleSetTransferData = (data: iTransferDataProps) => {
        const transferType = isDialpadTransfer ? 'voice' : 'chat';
        setTransferData(data);
        saveTransferSelections('initTransferData', data, transferType);
    }

    const handleOption = (selected: any) => {
        const isSecureIVR = (selected === "Secure IVR") || false;
        const isVoicemailMessage = (selected === "Voicemail Message") || false;
        const isDialExtension = (selected === "Dial Extension") || false;
        // dunamis TCP queue click event
        if (typeof window.dunamis_analytics.sendTCPEvent === 'function') {
            window.dunamis_analytics.sendTCPEvent({
                subType: selected
            })
        }
        if (transferData.nonEnglishQueue) {
            handleSetTransferData({ ...transferData, transferTabActive: selected, isSecureIVR, isVoicemailMessage, isDialExtension });
            setFilteredItems(transferQueue?.[selected] || []);
            setQueueSearchText('');
            setDirectSearchText('');
        } else {
            handleSetTransferData({ ...transferData, transferTabActive: selected, isSecureIVR, isVoicemailMessage, isDialExtension });
        }
    }

    const setSelected = (selected: any) => {
        // dunamis TCP queue selection click event
        if (typeof window.dunamis_analytics.sendTCPEvent === 'function') {
            window.dunamis_analytics.sendTCPEvent({
                subType: 'transfer-group',
                value: selected
            })
        }

        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: "queue", // override-suggestion
                subCategory: 'Override Suggestion',
                "ui.view_type": "queue-transfer-assistance",
                "content.name": getTapToolRes,
                value: selected
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        const filterWith = rtq ? currentConversation?.jcAuthData?.currentQueue?.value : selected;
        const nonEnQueue = (transferData.nonEnglishQueue && transferData.transferTabActive === "Queue") || '';
        if (tabId === TAB_ID.TRANSFER_TO_ASSISTANCE || nonEnQueue) {
            transferData.transferToAssistance = filteredItems.find((x: any) => x.name === filterWith);
            handleSetTransferData(transferData);
        } else {
            transferData.transferToDirect = filteredItems.find((x: any) => x.name === filterWith);
            handleSetTransferData(transferData);
        }
    }

    const numberWithCommas = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const setDropdownOnClick = (isOpen: boolean, menuTrigger: any) => {
        let activeOption = tabId === TAB_ID.TRANSFER_TO_ASSISTANCE ? 'Queue' : 'Manager';
        if (transferData.nonEnglishQueue) {
            activeOption = transferData?.transferTabActive || 'Queue'
        }
        if (isOpen && menuTrigger === 'manual') {
            setFilteredItems(transferQueue?.[activeOption] || [])
        }
    }

    const closeHandle = (e: any) => {
        // dunamis TCP queue click event
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: e.target.dataset.analyticsBtnType, // this will be transfer/cancel/close
                subCategory: e.target.dataset.analyticsBtnType === 'cancel' ? 'Override Suggestion' : 'Transfer Chat',
            }

            if (e.target.dataset.analyticsBtnType === 'cancel') data.count = tranferCnt || 0;
            window.dunamis_analytics.sendTapToolEvents(data);
        }
        const transferType = isDialpadTransfer ? 'voice' : 'chat';
        closePopup(close, true, transferType);
    }

    const handleSetTabId = (selectTabId: number) => {
        if (!transferData.nonEnglishQueue) {
            const activeOption = (selectTabId === TAB_ID.TRANSFER_TO_ASSISTANCE) ? 'Queue' : 'Manager';

            setTabId(selectTabId);
            setFilteredItems(transferQueue?.[activeOption] || []);
            handleSetTransferData({ ...transferData, tabId: selectTabId });
        }
    }

    const transferVoiceCall = (e: any) => {
        const type = e.target.id;

        if (transferData.isSecureIVR) {
            const reqData = {
                "queue": {
                    "name": "Secure IVR",
                    "type": "queue"
                }
            }
            callBackEvent('WARM_TRANSFER_CALL', reqData);
            return;
        }

        if (transferData.isVoicemailMessage) {
            const reqData = {
                "queue": {
                    "name": "Voicemail Message",
                    "type": "queue"
                }
            }
            callBackEvent('WARM_TRANSFER_CALL', reqData);
            return;
        }

        if (transferData.isDialExtension) {
            const reqData: any = {
                "queue": {
                    "name": "SLS-Agent-Transfer",
                    "type": "queue"
                }
            }

            reqData.message = transferData?.messageDirect
            callBackEvent('WARM_TRANSFER_CALL', reqData);
            return;
        }

        const transferToAssistanceName = transferData?.transferToAssistance?.name || '';
        const transferDirectName = transferData?.transferToDirect?.name || '';
        let queueName = transferData?.transferTabActive === 'Queue' ? transferToAssistanceName : transferDirectName;

        if (tabId === TAB_ID.TRANSFER_TO_ASSISTANCE && transferData?.transferTabActive === 'Queue' && !overrideSuggestion) {
            queueName = transferData?.resultQueue?.queue || ''
        }

        if (!queueName) {
            return;
        }
        const reqData: any = { queue: { name: queueName } };
        if (tabId === TAB_ID.TRANSFER_TO_ASSISTANCE || transferData?.transferTabActive === 'Queue') {
            reqData.queue.type = 'queue'
            reqData.overrideSuggestion = transferData?.overrideSuggestion || ''
            reqData.message = transferData?.nonEnglishQueue ? transferData?.messageDirect : transferData?.messageAssistance
        } else {
            reqData.queue = transferData?.transferToDirect;
            reqData.queue.type = 'agent'
            reqData.message = transferData?.messageDirect
        }
        if (type === TRANSFER_VOICE_CALL_TYPE.WARM) {
            callBackEvent('WARM_TRANSFER_CALL', reqData);
            setTransferState(TRANSFER_VOICE_CALL_TYPE.WARM);
        } else if (type === TRANSFER_VOICE_CALL_TYPE.COLD) {
            callBackEvent('COLD_TRANSFER_CALL', reqData);
            setTransferState(TRANSFER_VOICE_CALL_TYPE.COLD);
        }
        const transferType = isDialpadTransfer ? 'voice' : 'chat';
        closePopup(close, true, transferType);
    }

    const handleTransfer = (e: any) => {
        const buttonType = e.target.dataset.analyticsBtnType;

        switch (buttonType) {
            case "transfer":
                if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
                    window.dunamis_analytics.sendTapToolEvents({
                        type: 'click',
                        subType: buttonType, // this will be transfer
                        subCategory: 'Override Suggestion',
                        count: tranferCnt || 0
                    });

                    window.dunamis_analytics.sendTapToolEvents({
                        type: 'click',
                        subType: "reason", // override-suggestion
                        subCategory: 'Override Suggestion',
                        "ui.view_type": "queue-transfer-assistance",
                        "content.name": getTapToolRes,
                        value: transferData.overrideSuggestion || ''
                    });
                }

                transferFun(transferData, close, false);
                break;

            case "transfer-to-suggested-queue":
                if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
                    const data: any = {
                        type: 'click',
                        subType: buttonType, // transfer-to-suggested-queue
                        subCategory: 'Queue Transfer Assistance',
                        value: getTapToolRes,
                        "ui.view_type": "queue-transfer-assistance"
                    }

                    window.dunamis_analytics.sendTapToolEvents(data);
                }

                transferFun(transferData, close, true);
                break;

            case "cold-transfer":
                if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
                    const data: any = {
                        type: 'click',
                        subType: buttonType, // transfer-to-suggested-queue
                        subCategory: 'Transfer Chat',
                        value: "cold-transfer",
                    }

                    window.dunamis_analytics.sendTapToolEvents(data);
                }

                transferVoiceCall(e);
                break;

            case "warm-transfer":
                if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
                    const data: any = {
                        type: 'click',
                        subType: buttonType, // transfer-to-suggested-queue
                        subCategory: 'Transfer Chat',
                        value: "warm-transfer",
                    }

                    window.dunamis_analytics.sendTapToolEvents(data);
                }

                transferVoiceCall(e);
                break;
        }
    }

    const handleOverrideSuggestion = (e: any) => {
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: e.target.dataset.analyticsBtnType, // override-suggestion
                subCategory: 'Queue Transfer Assistance',
                value: getTapToolRes,
                "ui.view_type": "queue-transfer-assistance"
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        setOverrideSuggestion(true);
    }

    const setTapToolSelectionList = (selected: any, index: number) => {
        const newTapToolSelected = [...transferData.resultQueueList];
        const tapToolSelection = newTapToolSelected.slice(0, index + 1);
        const TapToolItem = TapTool[selected] || '';

        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const allowedTapToolAnalyticKeys = ["contact", "channel", "license", "issue-type"];
            const data: any = {
                type: 'click',
                subType: allowedTapToolAnalyticKeys[index + 1] || "", // override-suggestion
                subCategory: 'Queue Transfer Assistance',
                value: selected,
                "ui.view_type": "queue-transfer-assistance"
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        if (!TapToolItem) {
            handleSetTransferData({ ...transferData, resultQueue: {} });
            return;
        }
        let newResultQueue = (TapToolItem?.type === 'results') ? TapToolItem : {}
        if (TapToolItem?.type === "filter") {
            tapToolSelection.push(selected);
            const filterPopulate = isDialpadTransfer ? `${selected}/voice` : `${selected}/chat`;
            tapToolSelection.push(filterPopulate);
            newResultQueue = TapTool[filterPopulate] || '';
        } else {
            tapToolSelection.push(selected);
        }

        handleSetTransferData({ ...transferData, resultQueue: newResultQueue, resultQueueList: tapToolSelection });
    }

    const handleQueueTransferAssistanceNotes = (e: any) => {
        const isQueueTransfer = e.target.dataset.transferMessageType === 'queue-transfer-message';
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: "Add Transfer notes", // override-suggestion
                subCategory: `${isQueueTransfer ? 'Queue' : 'Direct'} Transfer Assistance`,
                value: e.target.value,
                "ui.view_type": isQueueTransfer ? "queue-transfer-assistance" : "direct-transfer-assistance"
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        const key = isQueueTransfer ? 'messageAssistance' : 'messageDirect';
        handleSetTransferData({ ...transferData, [key]: e.target.value || '' });
    }

    const handleOverrideSuggestionTransferNotes = (e: any) => {
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: "Add Transfer notes", // override-suggestion
                subCategory: 'Override Suggestion',
                value: e.target.value,
                "ui.view_type": "queue-transfer-assistance",
                "content.name": getTapToolRes
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        handleSetTransferData({ ...transferData, 'messageAssistance': e.target.value || '' });
    }

    const handleBackBtn = () => {
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: "back", // override-suggestion
                subCategory: 'Override Suggestion',
                "ui.view_type": "queue-transfer-assistance",
                "content.name": getTapToolRes
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        setOverrideSuggestion(false);
    }

    const handleOverridingReason = (e: any) => {
        handleSetTransferData({ ...transferData, 'overrideSuggestion': e.target.value || '' });
    }

    const handleOverridingQueueChange = (selected: any) => {
        if (typeof window.dunamis_analytics.sendTapToolEvents === 'function') {
            const data: any = {
                type: 'click',
                subType: "queue", // override-suggestion
                subCategory: 'Override Suggestion',
                "ui.view_type": "queue-transfer-assistance",
                "content.name": getTapToolRes,
                value: selected
            }

            window.dunamis_analytics.sendTapToolEvents(data);
        }

        setSelected(selected);
    }

    const getTapToolResult = (taptoolRes: any) => {
        let result = '';

        if (taptoolRes?.queue && taptoolRes?.message) {
            result = `${taptoolRes?.queue} <br /> ${taptoolRes?.message}`;
        } else if (taptoolRes?.queue &&  taptoolRes?.['message-alt']) {
            result = `${taptoolRes?.queue} <br /> ${taptoolRes?.['message-alt']}`;
        } else if (taptoolRes?.queue) {
            result = taptoolRes?.queue   
        } else if (allowQueueCheck() && allowLangCheck() && taptoolRes?.message) {
            result = taptoolRes?.message
        } else if (allowQueueCheck() && taptoolRes?.['message-alt']) {
            result = taptoolRes?.['message-alt'] || '';
        } else if (taptoolRes?.message) {
            result = taptoolRes?.message;
        }

        return result;
    }

    const getTransferBtnState = (transferToAssistanceName: string, transferDirectName: string) => {
        const nonEnQueue = (transferData.nonEnglishQueue && transferData.transferTabActive === "Queue") || '';

        if (tabId === TAB_ID.DIRECT_TRANSFER && isDialpadTransfer && (transferData.transferTabActive === "Secure IVR" || transferData.transferTabActive === "Voicemail Message" || transferData.transferTabActive === "Dial Extension")) {
            return false
        }

        if (tabId === TAB_ID.TRANSFER_TO_ASSISTANCE || nonEnQueue) {
            if (!overrideSuggestion && !rtq && resultQueue) {
                return false
            }
            if (overrideSuggestion && resultQueue && transferToAssistanceName && transferData.overrideSuggestion) {
                return false
            }
            if (overrideSuggestion && !resultQueue && transferToAssistanceName && transferData.overrideSuggestion) {
                return false
            }
            if (rtq && transferToAssistanceName) {
                return false
            }
            if (nonEnQueue && transferToAssistanceName) {
                return false
            }
        }
        if (tabId === TAB_ID.DIRECT_TRANSFER && transferData.transferTabActive === "Manager" && transferDirectName) {
            return false
        }
        return true;
    }

    const getSelectedQueueList = () => {
        const newQueueList = [...transferData.resultQueueList] || [];
        const TapToolItem = TapTool[lastResultQueueEle] || {};

        if (TapToolItem.type === 'results') {
            newQueueList.pop();
            return newQueueList;
        }
        return newQueueList;
    }

    const getQuickConnectConfigurationText = (recommendedQueue: boolean) => {
        const text = recommendedQueue ? localization[lang].TRANSFER_CALL.RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE : localization[lang].TRANSFER_CALL.YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS;
        return (
            <div className={styles['toolbar-error-text']}> {text} </div>
        )
    }

    const getLabelByLangAndQueue = (data: any) => {
        const { root, path } = data;
        try {
            if (propQueueCheck === lang) {
                if (root) return TAP_TOOL_DEFAULT?.label || '';
                if (!path) return '';
                return (TapTool?.[path]?.label || '');
            } else if (propQueueCheck !== lang) {
                if (root) return TAP_TOOL_DEFAULT?.['label-alt'] || TAP_TOOL_DEFAULT?.['label'] ||'';
                if (!path) return '';
                return (TapTool?.[path]?.['label-alt'] || TapTool?.[path]?.label || '');
            }
            if (root) return TAP_TOOL_DEFAULT?.label || '';
            if (!path) return '';
            return (TapTool?.[path]?.label || TapTool?.[path]?.filter || '');
        }
        catch (e) {
            if (root) return TAP_TOOL_DEFAULT?.label || '';
            if (!path) return '';
            return (TapTool?.[path]?.label || TapTool?.[path]?.filter || '');
        }
    }

    const getNameByLangAndQueue = (item: any) => {
        try {
            if (propQueueCheck === lang) return item?.name || '';
            if (propQueueCheck !== lang) return item?.['name-alt'] || item?.name || '';
            return item?.name || '';
        } catch (e) {
            return item?.name || '';
        }
    }

    const allowQueueCheck = () => {
        const allowQueue = ['-Ja', '-Ko', '-Fr', '-It', '-Es', '-Pt', '-De', '-En'];
        return allowQueue.some(substring => propQueue.includes(substring))
    }

    const allowLangCheck = () => {
        const allowLang = ['ja', 'ko', 'fr', 'it', 'es', 'pt-BR', 'de', 'en'];
        return allowLang.includes(lang);
    }

    const getTapToolRes = getTapToolResult(transferData.resultQueue);
    const {
        queue: resultQueue = '',
        type = '',
        doNotTransferQueues = []
    } = transferData.resultQueue;

    const transferToAssistanceName = transferData?.transferToAssistance?.name || '';
    const transferDirectName = transferData?.transferToDirect?.name || '';
    const doNotTransferQueue = (type === 'results' && doNotTransferQueues.includes(propQueue)) || (resultQueue && agentRoutingProfileQueues.length > 0 && agentRoutingProfileQueues.includes(resultQueue)) || false;
    const transferBtn: boolean = getTransferBtnState(transferToAssistanceName, transferDirectName) || false;
    const lastResultQueueEle: any = transferData.resultQueueList.at(-1) || {};
    const resultQueueList = getSelectedQueueList()
    const isTapRecommendedQueue = (resultQueue && !overrideSuggestion && !transferQueue?.Queue?.find((i: any) => i?.name === resultQueue)) || false;
    const checkTapQueue = transferQueue?.Queue?.find((i: any) => i?.name === resultQueue)
    const isTapQueueAvailable = (checkTapQueue?.name && checkTapQueue?.status === 'Available') || false;
    return (
        <div className={isDialpadTransfer && styles['dialpad-transfer-container']}>
            <Flex UNSAFE_className={styles['transfer-heading']}>
                <Text UNSAFE_className={styles['transfer-heading-text']}>
                    {localization[lang].TRANSFER_CALL.TRANSFER}
                </Text>
                <ActionButton onPress={closeHandle} isQuiet UNSAFE_className={styles['transfer-modal-close']}><Close /></ActionButton>
            </Flex>
            <Flex UNSAFE_className={`${styles['toolbar-transfer-tab-container']}`}>
                <Flex UNSAFE_className={`${styles['toolbar-tab-continer']} ${darkMode && styles['toolbar-tab-container-dark']}`}>
                    <div className={`${styles['toolbar-tab-left']} ${tabId === TAB_ID.TRANSFER_TO_ASSISTANCE && styles['toolbar-tab-left-active']} ${(transferData?.nonEnglishQueue || quickConnectConfiguration.Queue) && styles['toolbar-tab-left-disable']}`} onClick={() => handleSetTabId(TAB_ID.TRANSFER_TO_ASSISTANCE)}>{localization[lang].TRANSFER_CALL.QUEUE_TRANSFER_ASSISTANCE}</div>
                    <div className={`${styles['toolbar-tab-right']} ${tabId === TAB_ID.DIRECT_TRANSFER && styles['toolbar-tab-right-active']}`} onClick={() => handleSetTabId(TAB_ID.DIRECT_TRANSFER)}>{localization[lang].TRANSFER_CALL.DIRECT}</div>
                </Flex>
                {tabId === TAB_ID.TRANSFER_TO_ASSISTANCE ?
                    <Flex direction="column">
                        {(overrideSuggestion || rtq) ?
                            <Flex direction="column" UNSAFE_className={`${styles['toolbar-override-suggestion']} ${rtq && styles['toolbar-override-suggestion-rtq']}`}>
                                <div className={styles['toolbar-override']}>
                                    <div className={styles['toolbar-override-left']} onClick={handleBackBtn}>
                                        {!rtq && <span className={styles['toolbar-override-left-back']}><ChevronLeft />{localization[lang].TRANSFER_CALL.BACK}</span>}
                                    </div>
                                    <div className={styles['toolbar-override-right']}>{localization[lang].TRANSFER_CALL.NUMBER_OF_TRANSFERS}: {currentConversation?.jcAuthData?.transferCount?.value || 0}</div>
                                </div>
                                {
                                    (resultQueue && !rtq) &&
                                    <label className={styles['suggested-queue-title']}>{localization[lang].TRANSFER_CALL.SUGGESTED_QUEUE_TEXT}</label>
                                }
                                {
                                    (resultQueue && !rtq) &&
                                    <Text UNSAFE_className={styles['suggested-queue-subtitle']}>{getTapToolRes}</Text>
                                }
                                {
                                    (!rtq) &&
                                    <div className={styles['override-suggestion-reason']}>
                                        <label>{localization[lang].TRANSFER_CALL.OVERRIDE_REASON}</label>
                                        <input
                                            aria-label={localization[lang].TRANSFER_CALL.OVERRIDE_REASON}
                                            className={`${styles['override-suggestion-reason-text']} ${styles[darkMode ? 'tn-border-dark' : 'tn-border-light']}`}
                                            value={transferData.overrideSuggestion}
                                            onChange={handleOverridingReason}
                                        />
                                    </div>
                                }
                                <ComboBox key='tab1' label={localization[lang].TRANSFER_CALL.QUEUE} shouldFlip={false} placeholder={localization[lang].TRANSFER_CALL.SELECT_QUEUE + "..."} UNSAFE_className={`${styles['transfer-combobox-' + (darkMode ? 'dark' : 'light')]} ${styles['toolbar-transfer-combobox-queue']}`} aria-label="Select"
                                    loadingState={loadingState} items={filteredItems || []} defaultSelectedKey={transferToAssistanceName} inputValue={rtq ? transferToAssistanceName : queueSearchText} onInputChange={(value) => setQueueSearchText(value.substring(0, 30))}
                                    isDisabled={rtq || quickConnectConfiguration.Queue} onSelectionChange={handleOverridingQueueChange} onOpenChange={(isOpen, menuTrigger) => setDropdownOnClick(isOpen, menuTrigger)}>
                                    {(item: any) =>
                                        <Item key={item.name} textValue={item.name}>
                                            <StatusLight aria-label={`Queue Status ${item.name}`} role="status" variant={statusVariantMap[item.status]} marginStart={0} UNSAFE_style={{ alignItems: 'center', fontStyle: 'normal', color: 'inherit' }} width={200}>
                                                {item.name}
                                            </StatusLight>
                                        </Item>
                                    }
                                </ComboBox>
                                <div className={styles['transfer-notes-container']}>
                                    <label className={styles['transfer-notes']}>{localization[lang].TRANSFER_CALL.ADD_TRANSFER_NOTES}</label>
                                    <div className={styles['transfer-note-wrapper']}>
                                        <textarea maxLength={transferNotesLimit} spellCheck="true" placeholder={localization[lang].TRANSFER_CALL.TEXTAREA_PLACEHOLDER + "..."}
                                            className={`${styles[isDialpadTransfer ? 'transfer-text-area-voice' : 'transfer-text-area']} ${styles[isDialpadTransfer ? 'transfer-note-voice-height-short' : 'transfer-note-height-short']} ${styles[darkMode ? 'tn-border-dark' : 'tn-border-light']}`}
                                            aria-label="Transfer notes" value={transferData.messageAssistance}
                                            onChange={handleOverrideSuggestionTransferNotes}
                                        />
                                        <div className={`${styles['transfer-limit']} ${darkMode && styles['transfer-limit-dark']}`}>{numberWithCommas(transferData.messageAssistance.length)} {localization[lang].TRANSFER_CALL.OF} {numberWithCommas(transferNotesLimit)}</div>
                                    </div>
                                </div>
                                {quickConnectConfiguration.Queue && getQuickConnectConfigurationText(false)}
                            </Flex>
                            :
                            <Flex direction="column" UNSAFE_className={styles['transfer-assistance']}>
                                <Picker margin="7px 0px" height="56px" align="start" selectedKey={transferData?.resultQueueList[0] || ''} label={getLabelByLangAndQueue({ root: true })} placeholder={`${getLabelByLangAndQueue({ root: true })}...`}
                                    width="100%" aria-label="Nature of Contact" items={TAP_TOOL_DEFAULT?.options || []} onSelectionChange={(selected) => setTapToolSelectionList(selected, -1)}>
                                    {(item : any) => <Item key={item?.path}>{getNameByLangAndQueue(item)}</Item>}
                                </Picker>
                                {
                                    resultQueueList?.map((tapToolItem: any, index: number) => {
                                        return (
                                            <Picker key={tapToolItem} margin="7px 0px" height="56px" align="start" selectedKey={transferData.resultQueueList[index + 1]} label={getLabelByLangAndQueue({ path: tapToolItem })} placeholder={`${getLabelByLangAndQueue({ path: tapToolItem })}...`}
                                                width="100%" aria-label="Contact" items={TapTool[tapToolItem].options || []} onSelectionChange={(selected) => setTapToolSelectionList(selected, index)}>
                                                {(item: any) => <Item key={item.path}>{getNameByLangAndQueue(item)}</Item>}
                                            </Picker>
                                        )
                                    })
                                }
                                {
                                    (resultQueue && !doNotTransferQueue) &&
                                    <div className={styles['transfer-notes-container']}>
                                        <label className={`${styles['transfer-notes']} ${styles['transfer-notes-override-suggest']}`}>{localization[lang].TRANSFER_CALL.ADD_TRANSFER_NOTES}</label>
                                        <div className={`${styles['transfer-note-wrapper']} ${styles['transfer-note-height-override-suggest']}`}>
                                            <textarea maxLength={transferNotesLimit} spellCheck="true" placeholder={localization[lang].TRANSFER_CALL.TEXTAREA_PLACEHOLDER + "..."}
                                                className={`${styles['transfer-text-area']} ${styles[darkMode ? 'tn-border-dark' : 'tn-border-light']}`}
                                                aria-label="Transfer notes" value={transferData.messageAssistance} data-transfer-message-type="queue-transfer-message"
                                                onChange={handleQueueTransferAssistanceNotes}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    (type === 'results') &&
                                    <div className={`${styles['suggested-transfer']} ${(!isTapQueueAvailable) && styles['suggested-offline-queue-color']}`}>
                                        <div className={styles['suggested-transfer-content']}>
                                            <div className={styles['suggested-transfer-icon']}>
                                                {(!isTapQueueAvailable) ?
                                                    <TooltipTrigger>
                                                        <ToggleButton isQuiet> <Clock /> </ToggleButton>
                                                        <Tooltip UNSAFE_className={styles['tb-btn-tooltip']} width={87}>{localization[lang].TRANSFER_CALL.QUEUE_IS_AFTER_HOURS}</Tooltip>
                                                    </TooltipTrigger>
                                                    :
                                                    <ConversationAssigned />}
                                            </div>
                                            <div className={styles['suggested-transfer-text']}>
                                                {resultQueue && <span className={styles['toolbar-suggested-transfer-header']}> {localization[lang].TRANSFER_CALL.SUGGESTED_QUEUE_TEXT} : </span>}
                                                <span className={styles['suggested-transfer-title']} dangerouslySetInnerHTML={{ __html: !doNotTransferQueue ? getTapToolRes : localization[lang].TRANSFER_CALL.DO_NOT_TRANSFER_MESSAGE }} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {resultQueue && isTapRecommendedQueue && getQuickConnectConfigurationText(true)}
                            </Flex>
                        }
                    </Flex>
                    :
                    <Flex direction="column" UNSAFE_className={styles['toolbar-direct-transfer']}>
                        {
                            ((transferData?.transferTabActive === 'Queue' && quickConnectConfiguration.Queue) || (transferData?.transferTabActive === 'Manager' && quickConnectConfiguration.Manager)
                                || (transferData.isSecureIVR && !quickConnectConfiguration.SecureIVR) || (transferData.isVoicemailMessage && !quickConnectConfiguration.VoicemailMessage) || (transferData.isDialExtension && !quickConnectConfiguration.DialExtension)) &&
                            <div className={styles['quick-connect-err']}>
                                <div className={styles['quick-connect-err-left']}>
                                    <Alert />
                                </div>
                                <div className={styles['quick-connect-err-right']}>
                                    {getQuickConnectConfigurationText(false)}
                                </div>
                            </div>
                        }
                        <label className={styles['transfer-text']}>
                            {localization[lang].TRANSFER_CALL.NUMBER_OF_TRANSFERS}: {currentConversation?.jcAuthData?.transferCount?.value || 0}
                        </label>
                        <Flex direction="column" justifyContent="space-between">
                            {
                                transferData.nonEnglishQueue ?
                                    <Picker align="start" selectedKey={transferData.transferTabActive} defaultSelectedKey={transferData.transferTabActive} UNSAFE_className={styles['transfer-dropdown']} aria-label="Direct Transfer"
                                        onSelectionChange={handleOption}>
                                        <Item key="Queue" textValue={localization[lang].TRANSFER_CALL.QUEUE}>{localization[lang].TRANSFER_CALL.QUEUE}</Item>
                                        <Item key="Manager" textValue={localization[lang].TRANSFER_CALL.MANAGER}>{localization[lang].TRANSFER_CALL.MANAGER}</Item>
                                        {(isDialpadTransfer && quickConnectConfiguration.SecureIVR) && <Item key="Secure IVR" textValue={localization[lang].TRANSFER_CALL.IVR}>{localization[lang].TRANSFER_CALL.IVR}</Item>}
                                        {(isDialpadTransfer && quickConnectConfiguration.VoicemailMessage) && <Item key="Voicemail Message" textValue="Voicemail Message">Voicemail Message</Item>}
                                        {(isDialpadTransfer && quickConnectConfiguration.DialExtension) && <Item key="Dial Extension" textValue="Dial Extension">SLS-Agent-Transfer</Item>}
                                    </Picker>
                                    :
                                    <Picker align="start" selectedKey={transferData.transferTabActive} defaultSelectedKey={transferData.transferTabActive} UNSAFE_className={styles['transfer-dropdown']} aria-label="Direct Transfer"
                                        onSelectionChange={handleOption}>
                                        <Item key="Manager" textValue={localization[lang].TRANSFER_CALL.MANAGER}>{localization[lang].TRANSFER_CALL.MANAGER}</Item>
                                        {(isDialpadTransfer && quickConnectConfiguration.SecureIVR) && <Item key="Secure IVR" textValue={localization[lang].TRANSFER_CALL.IVR}>{localization[lang].TRANSFER_CALL.IVR}</Item>}
                                        {(isDialpadTransfer && quickConnectConfiguration.VoicemailMessage) && <Item key="Voicemail Message" textValue="Voicemail Message">Voicemail Message</Item>}
                                        {(isDialpadTransfer && quickConnectConfiguration.DialExtension) && <Item key="Dial Extension" textValue="Dial Extension">SLS-Agent-Transfer</Item>}
                                    </Picker>
                            }
                            {
                                (transferData.nonEnglishQueue && transferData.transferTabActive === 'Queue') ?
                                    <ComboBox key='tab1' shouldFlip={false} placeholder={localization[lang].TRANSFER_CALL.SELECT_QUEUE + "..."} UNSAFE_className={styles['transfer-combobox-' + (darkMode ? 'dark' : 'light')]} aria-label="Select"
                                        loadingState={loadingState} items={filteredItems || []} defaultSelectedKey={transferToAssistanceName} inputValue={rtq ? transferToAssistanceName : queueSearchText} onInputChange={(value) => setQueueSearchText(value.substring(0, 30))}
                                        isDisabled={rtq || transferData.isSecureIVR || quickConnectConfiguration.Queue || transferData.isVoicemailMessage || transferData.isDialExtension} onSelectionChange={handleOverridingQueueChange} onOpenChange={(isOpen, menuTrigger) => setDropdownOnClick(isOpen, menuTrigger)}>
                                        {(item: any) =>
                                            <Item key={item.name} textValue={item.name}>
                                                <StatusLight aria-label={`Queue Status ${item.name}`} role="status" variant={statusVariantMap[item.status]} marginStart={0} UNSAFE_style={{ alignItems: 'center', fontStyle: 'normal', color: 'inherit' }} width={200}>
                                                    {item.name}
                                                </StatusLight>
                                            </Item>
                                        }
                                    </ComboBox>
                                    :
                                    <ComboBox key='tab2' shouldFlip={false} placeholder={localization[lang].TRANSFER_CALL.SELECT_QUEUE + "....."} UNSAFE_className={styles['transfer-combobox-' + (darkMode ? 'dark' : 'light')]} aria-label="Select"
                                        loadingState={loadingState} items={filteredItems || []} defaultSelectedKey={transferDirectName} inputValue={rtq ? transferDirectName : directSearchText} onInputChange={(value) => setDirectSearchText(value.substring(0, 30))}
                                        isDisabled={rtq || transferData.isSecureIVR || quickConnectConfiguration.Manager || transferData.isVoicemailMessage || transferData.isDialExtension} onSelectionChange={setSelected} onOpenChange={(isOpen, menuTrigger) => setDropdownOnClick(isOpen, menuTrigger)}>
                                        {(item: any) =>
                                            <Item key={item.name} textValue={item.name}>
                                                {item.name}
                                            </Item>
                                        }
                                    </ComboBox>
                            }
                        </Flex>
                        <div className={styles['transfer-notes-container']}>
                            <label className={styles['transfer-notes']}>{localization[lang].TRANSFER_CALL.ADD_TRANSFER_NOTES}</label>
                            <div className={styles['transfer-note-wrapper']}>
                                <textarea maxLength={transferNotesLimit} spellCheck="true" placeholder={localization[lang].TRANSFER_CALL.TEXTAREA_PLACEHOLDER + "..."}
                                    className={`${styles['transfer-text-area']} ${styles[isDialpadTransfer ? 'transfer-note-height-long-voice' : 'transfer-note-height-long']} ${styles[darkMode ? 'tn-border-dark' : 'tn-border-light']}`}
                                    aria-label="Transfer notes" value={transferData.messageDirect} data-transfer-message-type="direct-transfer-message"
                                    onChange={handleQueueTransferAssistanceNotes} />
                                <div className={`${styles['transfer-limit']} ${darkMode && styles['transfer-limit-dark']}`}>{numberWithCommas(transferData.messageDirect.length)} {localization[lang].TRANSFER_CALL.OF} {numberWithCommas(transferNotesLimit)}</div>
                            </div>
                        </div>
                    </Flex>
                }
                {
                    !isDialpadTransfer ?
                        <Footer UNSAFE_className={styles['transfer-button-group']}>
                            <Button variant="secondary" isQuiet onPress={closeHandle}>
                                {localization[lang].ACTION_MODAL.CANCEL}
                            </Button>
                            {(!overrideSuggestion && tabId === TAB_ID.TRANSFER_TO_ASSISTANCE && !rtq) ?
                                <Flex direction="column" alignItems="center" gap="10px">
                                    <Button variant="cta" data-analytics-btn-type="transfer-to-suggested-queue" onPress={handleTransfer} isDisabled={doNotTransferQueue || !resultQueue || quickConnectConfiguration.Queue || isTapRecommendedQueue}>
                                        {localization[lang].TRANSFER_CALL.TRANSFER_TO_SUGGESTED}
                                    </Button>
                                    <button className={`${styles['override-link']} ${darkMode && styles['override-link-dark']} ${(getTapToolRes) && styles['override-link-active']}`} disabled={!getTapToolRes}
                                        onClick={handleOverrideSuggestion}>
                                        {localization[lang].TRANSFER_CALL.OVERRIDE}
                                    </button>
                                </Flex>
                                :
                                <Button variant="cta" data-analytics-btn-type="transfer" onPress={handleTransfer} isDisabled={transferBtn || quickConnectConfiguration.Queue}>
                                    {localization[lang].TRANSFER_CALL.TRANSFER}
                                </Button>
                            }
                        </Footer>
                        :
                        <Footer UNSAFE_className={styles['transfer-button-group']}>
                            {(tabId === TAB_ID.TRANSFER_TO_ASSISTANCE) ?
                                <div>
                                    <Flex UNSAFE_className={styles['transfer-button-group-sec']}>
                                        <Button variant="primary" data-analytics-btn-type="cold-transfer" UNSAFE_className={(transferState === TRANSFER_VOICE_CALL_TYPE.COLD) ? styles['transfer-cold-active'] : ''} id={TRANSFER_VOICE_CALL_TYPE.COLD} onPress={handleTransfer} isDisabled={transferBtn || transferState === TRANSFER_VOICE_CALL_TYPE.WARM || quickConnectConfiguration.Queue || isTapRecommendedQueue || (!overrideSuggestion && doNotTransferQueue)}>
                                            {localization[lang].TRANSFER_CALL.COLD_TRANSFER}
                                        </Button>
                                        <Button variant="cta" id={TRANSFER_VOICE_CALL_TYPE.WARM} data-analytics-btn-type="warm-transfer" onPress={handleTransfer} isDisabled={transferBtn || isTransfer || quickConnectConfiguration.Queue || isTapRecommendedQueue || (!overrideSuggestion && doNotTransferQueue)}>
                                            {localization[lang].TRANSFER_CALL.WARM_TRANSFER}
                                        </Button>
                                    </Flex>
                                    {
                                        !overrideSuggestion &&
                                        <Flex UNSAFE_className={styles['transfer-button-group-sec-suggestion']}>
                                            <button className={`${styles['override-link']} ${darkMode && styles['override-link-dark']} ${(getTapToolRes) && styles['override-link-active']}`}
                                                onClick={handleOverrideSuggestion} disabled={!getTapToolRes}>
                                                {localization[lang].TRANSFER_CALL.OVERRIDE}
                                            </button>
                                        </Flex>
                                    }
                                </div>
                                :
                                <Flex UNSAFE_className={styles['transfer-button-group-sec']}>
                                    <Button UNSAFE_className={styles['transfer-btn-warm']} variant="secondary" isQuiet onPress={closeHandle}>
                                        {localization[lang].ACTION_MODAL.CANCEL}
                                    </Button>
                                    <Button variant="cta" id={TRANSFER_VOICE_CALL_TYPE.WARM} data-analytics-btn-type="warm-transfer" onPress={handleTransfer} isDisabled={transferBtn || isTransfer || (transferData?.transferTabActive === 'Queue' && quickConnectConfiguration.Queue) || (transferData?.transferTabActive === 'Manager' && quickConnectConfiguration.Manager) || (transferData?.transferTabActive === 'Secure IVR' && !quickConnectConfiguration.SecureIVR) || (transferData?.transferTabActive === 'Voicemail Message' && !quickConnectConfiguration.VoicemailMessage) || (transferData?.transferTabActive === 'Dial Extension' && !quickConnectConfiguration.DialExtension)}>
                                        {localization[lang].TRANSFER_CALL.START_TRANSFER}
                                    </Button>
                                </Flex>
                            }
                        </Footer>
                }
            </Flex>
        </div>
    )
}

export default TransferCall;
