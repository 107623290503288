import React from 'react'

type Props = {
  fill?: string,
  style?: React.CSSProperties
}

const SentimentPositive = ({ fill = '#464646', style }: Props) => (
  <svg aria-label='positive sentiment' style={style} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path style={{ fill }} d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM6.1825,4.267a1.71956,1.71956,0,0,1,1.5,1.875,1.71956,1.71956,0,0,1-1.5,1.875,1.71956,1.71956,0,0,1-1.5-1.875A1.71921,1.71921,0,0,1,6.1825,4.267Zm5.554-.051a1.72023,1.72023,0,0,1,1.5,1.876,1.71921,1.71921,0,0,1-1.5,1.875,1.71921,1.71921,0,0,1-1.5-1.875A1.72023,1.72023,0,0,1,11.7365,4.216ZM9,14.02A4.92847,4.92847,0,0,1,4,9.95H14A4.92847,4.92847,0,0,1,9,14.02Z" />
  </svg>)

export default SentimentPositive
